import { media } from '../../theme/breakpoints'
import * as colors from '../../theme/colors'
import * as mixins from '../../utils/mixins';

export const main = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}

export const wrapper = {
  py: 0,
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '250px 1fr',
  minHeight: '100vh',
  [media.tablet]: {
    display: 'block',
  },
}

export const content = {
  backgroundColor: '#f5f5f5',
  color: '#1d222d',
  position: 'relative',
  maxWidth: 960,
  py: 5,
  px: 4,
  variant: 'styles.Container',
  [media.tablet]: {
    py: 4,
    px: 4,
    pt: 5,
  },
}

export const menuIcon = {
  display: 'none',
  position: 'absolute',
  top: -30,
  left: 0,
  [media.tablet]: {
    display: 'block',
  },
}

export const menuButton = {
  ...mixins.ghostButton,
  color: colors.grayLight,
  opacity: 0.5,
  cursor: 'pointer',
}
