export const centerAlign = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export const ghostButton = {
    p: 0,
    outline: 'none',
    background: 'transparent',
    border: 'none',
    ':hover': {
        cursor: 'pointer',
    },
}