import * as colors from '../../theme/colors';

export const link = {
  my: 2,
  display: 'block',
  color: colors.grayExtraLight,
  textDecoration: 'none',
  fontSize: 3,
  '&.active': {
    color: colors.skyBlue,
  },
}

export const smallLink = {
  ...link,
  ml: 3,
  fontSize: 3,
  position: 'relative',
  color: colors.grayExtraLight,
  '&.active': {
    color: colors.grayLight,
  },
  '&.active::before': {
    content: '""',
    position: 'absolute',
    display: 'block',
    top: '2px',
    left: -2,
    height: '1rem',
    backgroundColor: colors.skyBlue,
    transition: 'width 200ms ease 0s',
    width: '2px',
    borderRadius: 1,
  },
}