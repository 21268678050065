import * as React from 'react'
import { Helmet } from 'react-helmet-async'

const Wrapper = ({ children }) => <React.Fragment>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Realigned Technologies Documentation</title>
        <link rel="icon"
              type="image/png"
              href="https://docz.realigned.ch/public/iconblue.png"
        />
        <script async defer data-domain="docz.realignedtechnologies.com" src="https://plausible.io/js/plausible.js"></script>
    </Helmet>
    {children}
</React.Fragment>
export default Wrapper