/** @jsx jsx */
import { useRef, useState } from 'react'
import { jsx, Layout as BaseLayout, Main, Container, Box } from 'theme-ui'
import { Global } from '@emotion/core'
import { Menu } from '../Icons';

import global from '../../theme/global'
import { Sidebar } from '../Sidebar'
import * as styles from './styles'

export const Layout = ({ children }) => {
  const [open, setOpen] = useState(false)
  const nav = useRef()

  return (
    <BaseLayout sx={{ '& > div': { flex: '1 1 auto' } }} data-testid="layout">
      <Global styles={global} />
      <Main sx={styles.main}>
        <div sx={styles.wrapper}>
          <Sidebar
            ref={nav}
            open={open}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            onClick={() => setOpen(false)}
          />

          <Container sx={styles.content} data-testid="main-container">
              <div style={{position:'relative',zIndex:1}}>
                  <Box sx={styles.menuIcon}>
                      <button sx={styles.menuButton} onClick={() => setOpen(s => !s)}>
                          <Menu size={25} />
                      </button>
                  </Box>
              </div>
            {children}
          </Container>
        </div>
      </Main>
    </BaseLayout>
  )
}