import * as colors from "../../theme/colors";

export const wrapper = {
    mb: 3,
    display: 'inline-flex',
    alignItems: 'center',
}

export const input = {
    outline: 'none',
    background: 'none',
    border: 'none',
    color: colors.grayExtraLight,
    fontSize: 1,
}

export const icon = {
    color: colors.grayExtraLight,
    mr: 2,
}
